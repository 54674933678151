import { ApiService } from "./ApiService/ApiService";
import { urlConfig } from "../config/urlConfig";

export const homelist = (search, type, id) => {
  const url = `${urlConfig.home.homelist}?search=${search}&type=${type}&language_id=${id}`;
  return ApiService.get(url);
};
export const movielist = (type, id, category) => {
  const url = `${urlConfig.home.seeMore}?type=${type}&language_id=${id}&category=${category}`;
  return ApiService.get(url);
};
export const homeview = (id) => {
  const url = `${urlConfig.home.homeview}?id=${atob(id)}`;
  return ApiService.get(url);
};
export const getVideoUrl = (id) => {
  const url = `${urlConfig.home.player}?movie_id=${atob(id)}`;
  return ApiService.get(url);
};
export const createOrderForrentMovie = (payload) => {
  return ApiService.post(urlConfig.payment.createorderforRent, payload);
};
export const rentMovie = (payload) => {
  return ApiService.post(urlConfig.payment.rentMovie, payload);
};
export const createorderforsubscription = (payload) => {
  return ApiService.post(urlConfig.payment.createorderforsubscription, payload);
};
export const choosplan = (payload) => {
  return ApiService.post(urlConfig.payment.choosplan, payload);
};
export const getsubscriptionList = (payload) => {
  return ApiService.get(urlConfig.payment.subscriptionList);
};
export const getSongVimeoId = (payload) => {
  const url = `${urlConfig.song.getVimeoId}?song_id=${payload}`;
  return ApiService.get(url);
};
export const getTrailerVimeoId = (payload) => {
  const url = `${urlConfig.trailer.getVimeoId}?trailer_id=${payload}`;
  return ApiService.get(url);
};
export const getWishlist = (payload) => {
  return ApiService.get(urlConfig.wishlist.list);
};
export const toggleWishlist = (payload) => {
  return ApiService.post(urlConfig.wishlist.toggle, payload);
};

export const firstShowMovieList = (search, type, id) => {
  const language = localStorage.getItem("languageId")
    ? localStorage.getItem("languageId")
    : "";
  const url = `${urlConfig.firstshow.list}?search=${search}&type=first_show&language_id=${language}`;
  return ApiService.get(url);
};

export const subscribeMovieList = (search, type, id) => {
  const language = localStorage.getItem("languageId")
    ? localStorage.getItem("languageId")
    : "";
  const url = `${urlConfig.subscription.list}?search=${search}&type=subscribed&language_id=${language}`;
  return ApiService.get(url);
};

// Duration Update
export const durationUpdateApi = (payload) => {
  const data = {
    movie_id: atob(payload.movie_id),
    duration: payload.duration,
  };
  return ApiService.post(urlConfig.home.duration, data);
};

// Rate Movie
export const rateMovieApi = (payload) => {
  const data = {
    movie_id: atob(payload.movie_id),
    rating: payload.rating,
  };
  return ApiService.post(urlConfig.home.rate, data);
};
