import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../scss/MoreDetails.scss";
import deleteIcon from "../../images/AnalyticsPage/X-icon.svg";
import Button from "react-bootstrap/Button";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
// import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Banner1 from "../../images/MovieBanner/Uc.jpg";
import { getVideoUrl } from "../../services/home";
import Vimeo from "@u-wave/react-vimeo";
import { getanalytics, movetoSubscription } from "../../services/analytics";
import toast from "react-hot-toast";
import DeleteMovie from "./DeleteMovie";

export default function MovieAnalyticBanner() {
  const { id } = useParams();
  const [value, setValue] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    moredeatilsview();
  }, []);

  const moredeatilsview = async () => {
    try {
      const response = await getanalytics(`${parseInt(id)}&days=&search=`);
      if (response && response.data) {
        setValue(response.data?.data?.movie);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handlePlayClick = async () => {
  //   const response = await getVideoUrl(value.id);
  //   if (response && response.data) {
  //     setVideoUrl(response.data.data.vimeo_video_id);
  //   }
  // };

  const convertSecondsToHrMin = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return hours > 0 ? `${hours} Hr ${minutes} Min` : `${minutes} Min`;
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handlemovetoSub = async () => {
    try {
      const res = await movetoSubscription(id);
      if (res?.data?.status) {
        toast.success("Move To Subscription successfully");
        moredeatilsview();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="main-back">
      <div className="detail-container">
        <div className="image-section">
          {videoUrl ? (
            <div className="video-player">
              <Vimeo video={videoUrl} autoplay responsive />
            </div>
          ) : (
            <>
              <img
                src={isValidUrl(value?.cover_pic) ? value?.cover_pic : Banner1}
                alt={value.title || "Default image"}
                height={500}
              />
              <div className="overlay">
                <div className="movie-info">
                  <div>
                    <p
                      style={{
                        border: "1px solid white",
                        display: "inline-block",
                        padding: "0.3rem",
                      }}
                    >
                      <span>{value?.Certificate?.name}</span>
                    </p>
                  </div>
                  <h3>{value?.title}</h3>
                  <p>{value?.description}</p>
                  <p>
                    <AccessTimeOutlinedIcon />{" "}
                    {convertSecondsToHrMin(value.duration)} &middot;{" "}
                    {value?.Genre?.name} &middot; ⭐{value?.rating}
                  </p>
                  <div className="Butons-moredetails">
                    {value?.movie_plan === "first_show" ? (
                      <Button
                        variant="warning"
                        className="ticket-button"
                        onClick={handlemovetoSub}
                        disabled
                      >
                        Move to subscription
                      </Button>
                    ) : (
                      <Button
                        variant="warning"
                        className="ticket-button"
                        disabled
                      >
                        Already Moved to subscription
                      </Button>
                    )}
                    {/* <Button
                      style={{
                        backgroundColor: "#FFEDED",
                        color: "#EA4335",
                        marginLeft: "0.5rem",
                        borderRadius: "30px",
                        padding: "0 1    rem 0 1rem",
                        borderColor: "#FFEDED",
                      }}
                      onClick={HandleDelete}
                    >
                      <span>X</span> Delete this Movie
                    </Button> */}
                    <DeleteMovie selectedMovie={value} />
                    <div className="icons"></div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
