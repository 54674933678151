import { Close, Edit } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { deleteMovie } from "../../services/movieupload";
import toast from "react-hot-toast";
import crossIcon from "../../../src/images/Icons/crossIcon.svg";
import { useNavigate } from "react-router-dom";

const DeleteMovie = ({ selectedMovie }) => {
  const [IsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);

  const handleFormSubmit = async () => {
    setisLoading(true);
    try {
      const response = await deleteMovie(selectedMovie?.id);
      if (response?.data?.status) {
        toast.success("Movie deleted successfully");
        navigate("/home");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setisLoading(false);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
      }}
    >
      {/* <Button
        style={{
          backgroundColor: "#FFEDED",
          color: "#EA4335",
          marginLeft: "0.5rem",
          borderRadius: "30px",
          padding: "1rem",
          borderColor: "#FFEDED",
        }}
        startIcon={<Close />}
        onClick={() => {
          setIsOpen(!IsOpen);
        }}
      >
        Delete this Movie
      </Button> */}
      <Dialog
        open={IsOpen}
        onClose={() => {
          setIsOpen(!IsOpen);
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#000",
            color: "#FFF",
            width: "600px",
            padding: "36px 36px 48px 36px",
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div>
            <Typography
              variant="h6"
              color="inherit"
              style={{
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "23.44px",
                letterSpacing: "0.01em",
                textAlign: "left",
                width: "100%",
                color: "#FED530",
                marginBottom: "0.5rem",
              }}
            >
              {"Delete this movie"}
            </Typography>
            <Typography
              variant="h6"
              color="inherit"
              style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "20.83px",
                textAlign: "left",
                width: "470px",
              }}
            >
              Are you sure you want to delete this movie? This action cannot be
              undone.
            </Typography>
          </div>
          <div>
            <Button
              sx={{
                color: "white",
                position: "absolute",
                top: "-0",
                right: "-10px",
              }}
              onClick={() => {
                setIsOpen(!IsOpen);
              }}
            >
              <img src={crossIcon} alt="crossIcon" height={44} />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              gap: "2rem",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "12px",
                margin: "12px 0",
              }}
            >
              <img
                src={selectedMovie?.thumbnail}
                alt={selectedMovie?.title}
                style={{ width: "100px", height: "auto", objectFit: "cover" }}
              />
              <div>
                <Typography
                  variant="subtitle1"
                  className="transaction-title"
                  color="inherit"
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "23.44px",
                    textAlign: "left",
                    marginBottom: "0.5rem",
                    color: "#FFFF",
                    fontFamily: "DM Sans",
                  }}
                >
                  {selectedMovie?.title}
                </Typography>
              </div>
            </Box>
            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <Button
                sx={{
                  width: "176px",
                  fontFamily: "DM Sans",
                  height: "40px",
                  padding: "10px 20px 10px 16px",
                  borderRadius: "34px",
                  border: "1px solid #EA4335",
                  background: "#EA4335",
                  fontSize: "14px",
                  lineHeight: "24px",
                  textTransform: "capitalize",
                  fontWeight: "500",
                  color: "#000",
                  "&:hover": {
                    border: "1px solid #EA4335",
                    background: "#EA4335",

                    color: "#fff",
                  },
                }}
                variant="contained"
                onClick={handleFormSubmit}
              >
                Delete this Movie
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: "176px",
                  fontFamily: "DM Sans",
                  height: "40px",
                  padding: "10px 20px 10px 16px",
                  borderRadius: "34px",
                  border: "1px solid #404040",
                  background: "#000",
                  fontSize: "14px",
                  lineHeight: "24px",
                  textTransform: "capitalize",
                  fontWeight: "500",
                  "&:hover": {
                    border: "1px solid #000",
                    background: "#000",
                    color: "white",
                  },
                }}
                onClick={() => {
                  setIsOpen(!IsOpen);
                }}
              >
                Cancel{" "}
              </Button>
            </div>
          </Box>
        </DialogContent>
        <Backdrop
          open={isLoading}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </div>
  );
};

export default DeleteMovie;
