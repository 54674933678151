import React from "react";
import { Grid, Typography } from "@mui/material";
import ScreenImg3 from "../../images/screen3.png";
import { motion } from "framer-motion";
import "../../scss/Contact.scss";

const ViewersSays = () => {
  return (
    <Grid container className="screen-body pt-lg-5">
      <Grid container>
        <Grid item xs={12} md={6} sx={6}>
          <div>
            <img src={ScreenImg3} alt="Contact" />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            py: { xs: "1rem", md: 7 },
            px: { xs: "1rem", md: 10 },
          }}
        >
          <motion.div className="mt-5 pt-3"
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 1,
            scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
          }}
          >
            <Typography variant="para" className="screenPara1">
              VIEWER
            </Typography>
            <br />
            <Typography variant="para" className="screenPara2">
              SAYS
            </Typography>
            <br />
            <Typography variant="para" className="screenPara3">
              As a film enthusiast, I want to watch as many new films as
              possible. But with time constraint and other obstacles, I cannot
              catch up on every new release in theatres. Also at times, some
              films which I am really eager to watch at theatres won’t be
              available in my vicinity. In villages there are no theatres and in
              cities we don’t have time to visit theatres. Apart from theatres,
              most of the OTTs stream the movies after a month or so. By that
              time, films which once caught my attention fade out from my mind
              as I will be flooded with newly released movies. So, watching
              films on the day of its release remains a dream.
            </Typography>
          </motion.div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ViewersSays;
