import { Star, StarOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { deleteMovie } from "../../services/movieupload";
import toast from "react-hot-toast";
import crossIcon from "../../../src/images/Icons/crossIcon.svg";
import { useNavigate } from "react-router-dom";
import { ButtonGroup } from "react-bootstrap";
import { rateMovieApi } from "../../services/home";

const Rating = ({ selectedMovie }) => {
  const [IsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [filledStars, setFilledStars] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  // Function to handle star click
  const handleStarClick = (index) => {
    // Update state based on the clicked star index
    setFilledStars(filledStars.map((_, i) => i <= index));
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    const rating = filledStars.filter((isFilled) => isFilled).length;

    try {
      const response = await rateMovieApi({ movie_id: selectedMovie, rating });
      if (response && response.data) {
        toast.success(response?.data?.message);
        setTimeout(() => {
          navigate("/moredetails/" + selectedMovie);
        }, 1000);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
      }}
    >
      <Dialog
        open={true}
        // onClose={() => {
        //   setIsOpen(!IsOpen);
        // }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#000",
            color: "#FFF",
            width: "600px",
            padding: "36px 36px 48px 36px",
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div>
            <Typography
              variant="h6"
              color="inherit"
              style={{
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "23.44px",
                letterSpacing: "0.01em",
                textAlign: "left",
                width: "100%",
                color: "#FED530",
                marginBottom: "0.5rem",
                fontFamily: "DM Sans",
              }}
            >
              {"Would you like to rate this movie?"}
            </Typography>
            {/* <Typography
              variant="h6"
              color="inherit"
              style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "20.83px",
                textAlign: "left",
                width: "486px",
                color: "#E6E6E6",
                fontFamily: "DM Sans",
              }}
            >
              Corem ipsum dolor sit amet, consectetur elit.{" "}
            </Typography> */}
          </div>
          <div>
            <Button
              sx={{
                color: "white",
                position: "absolute",
                top: "-0",
                right: "-10px",
              }}
              onClick={() => {
                // setIsOpen(!IsOpen);
                navigate("/moredetails/" + selectedMovie);
              }}
            >
              <img src={crossIcon} alt="crossIcon" height={44} />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              gap: "2rem",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "12px",
                margin: "12px 0",
              }}
            >
              <ButtonGroup>
                {filledStars.map((isFilled, index) => (
                  <Button
                    key={index}
                    variant="text"
                    onClick={() => handleStarClick(index)}
                  >
                    {isFilled ? (
                      <Star
                        style={{
                          color: "#FED530",
                          fontSize: "36px",
                        }}
                      />
                    ) : (
                      <StarOutline
                        style={{
                          color: "#696969",
                          fontSize: "36px",
                        }}
                      />
                    )}
                  </Button>
                ))}
              </ButtonGroup>{" "}
            </Box>
            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <Button
                sx={{
                  width: "176px",
                  height: "40px",
                  padding: "10px 20px 10px 16px",
                  borderRadius: "34px",
                  border: "1px solid #FED530",
                  backgroundColor: "#FED530",
                  fontSize: "14px",
                  lineHeight: "24px",
                  textTransform: "capitalize",
                  fontWeight: "500",
                  color: "#000",
                  "&:hover": {
                    border: "1px solid #FED530",
                    backgroundColor: "#FED530",
                    color: "#000",
                  },
                }}
                style={{
                  cursor: `${loading ? "not-allowed" : "pointer"}`,
                }}
                onClick={handleFormSubmit}
              >
                {loading ? "Rating..." : "Rate this Movie"}
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Rating;
