import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Strings from "../string";
import { useAuth } from "../../routes/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { signupFailure } from "../../store/sample/action";
import AvatarImg from "../../images/Avatar.svg";
import { Add } from "@mui/icons-material";
import SearchCard from "./searchCard";
import { getprofileDetailS } from "../../services/account";
import { Skeleton } from "@mui/material";
import Logo from "../../images/cinetshow2.svg";
import logouticon from "../../images/logouticon.svg";
const Pages = [
  {
    name: "Home",
    submenuItems: [],
  },
  {
    name: "Movies",
    // submenuItems: ["Tickets", "Subscription", "Watchlist"], // Please dont remove this.....this is for future use
    submenuItems: ["Tickets", "Watchlist"],
  },
  {
    name: "Language",
    submenuItems: [],
  },
  {
    name: "Contact Us",
    submenuItems: [],
  },
  {
    name: "Filmmaker",
    submenuItems: [],
  },
];
export default function Navbar() {
  const location = useLocation();
  const userRole = localStorage.getItem("role");
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [activePage, setActivePage] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [loading, setisloading] = useState(true);
  const open = Boolean(anchorEl);
  const { user } = useSelector((state) => state.auth);

  const checkurl = () => {
    let firstSegment = location.pathname.split("/")[1];
    if (firstSegment === "") {
      return "home";
    } else {
      return firstSegment;
    }
  };
  const handleClick = (event, page) => {
    if (page?.submenuItems?.length > 0) {
      setAnchorEl(event.currentTarget);
      setActiveMenu(page.name);
    } else {
      setActiveMenu(page.name);
      handleCloseNavMenu();
      navigate(`/${page?.name.toLowerCase().replace(" ", "-")}`);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveMenu(null);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const storedUserName =
    localStorage.getItem("user_name") || Strings.LOGIN_SINGUP;
  const isAuthenticated = JSON.parse(localStorage.getItem("isAuthenticated"));
  const buttonStyleactive = {
    my: 2,
    color: "#FFF",
    display: "block",
    borderBottom: "solid 3px",
    borderColor: "#FED530",
    borderRadius: "0px",
    padding: "0px 20px",
    fontFamily: "DM Sans",
    fontWeight: "700",
    textTransform: "capitalize",
    fontSize: "16px",
    lineHeight: "32px",
    textAlign: "left",
  };
  const buttonStyleaNonctive = {
    my: 2,
    display: "block",
    borderBottom: "none",
    borderRadius: "0px",
    padding: "0px 20px",
    fontFamily: "DM Sans",
    fontWeight: "400",
    textTransform: "capitalize",
    fontSize: "16px",
    lineHeight: "32px",
    textAlign: "left",
    color: "rgba(230, 230, 230, 1)",
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (setting) => {
    setAnchorElUser(null);
    setisloading(true);
    if (setting === "Logout") {
      logout();
      dispatch(signupFailure(""));
    } else if (setting === "Account") {
      navigate(`/mainpage/${1}`);
    } else if (setting === "Profile") {
      navigate(`/mainpage/${0}`);
    } else if (setting === "Producer") {
      navigate(`/producer-details`);
    } else if (setting === "Your Uploads") {
      navigate(`/mainpage/${5}`);
    }
  };

  const handleMenuItemClick = (page, item) => {
    if (item?.submenuItems.length) {
      setActivePage(page);
    } else {
      setActivePage(page);
      handleClose();
      if (page === "Tickets") {
        navigate(`/movies/first-show`);
      }
      if (page === "Subscription") {
        navigate(`/movies/subscribe`);
      }
      if (page === "Watchlist") {
        navigate(`/movies/watch-list`);
      }
      if (page === "Filmmaker") {
        navigate(`/FAQs`);
      }
    }
    handleCloseNavMenu();
  };
  const handleNavigateTologin = () => {
    navigate("/login");
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await dispatch(getprofileDetailS());
        if (res?.data?.status) {
          setisloading(false);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setisloading(true);
      } finally {
        setisloading(false);
      }
    }
    if (isAuthenticated) {
      fetchData();
    }
  }, [loading]);

  const getProducercurrentrole = () => {
    if (userRole === "producer" && user?.approval === "pending") {
      return ["Profile", "Producer (Pending)", "Logout"];
    } else if (userRole === "producer" && user?.approval === "approve") {
      return ["Profile", "Your Uploads", "Logout"];
    }
    return ["Profile", "Producer", "Logout"];
  };
  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: "#1E1E1E",
          padding: {
            xs: "0",
            sm: "0",
            md: "12px 60px",
            xl: "12px 60px",
          },
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                mr: 5,
                display: { xs: "none", md: "flex" },
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/home");
              }}
            >
              <img src={Logo} alt="Logo" className="logo-image" />
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                  "& .MuiPaper-root": {
                    backgroundColor: "#000",
                    color: "#fff",
                  },
                }}
              >
                {Pages.map((page) => (
                  <div key={page}>
                    <MenuItem
                      onClick={(event) => handleClick(event, page)}
                      selected={activePage === page?.name}
                      sx={{
                        borderBottom:
                          activePage === page?.name ? "2px solid" : "none",
                        borderColor: "#FED530",
                        textTransform: "capitalize",
                      }}
                    >
                      <Typography textAlign="center">{page?.name}</Typography>
                    </MenuItem>
                    {Pages?.submenuItems?.length > 0 && (
                      <Menu
                        id={`submenu-${page.toLowerCase().replace(" ", "-")}`}
                        anchorEl={anchorElNav}
                        open={activePage === page}
                        onClose={handleCloseNavMenu}
                        sx={{
                          marginTop: "-8px",
                          "& .MuiPaper-root": {
                            backgroundColor: "#000",
                            color: "#fff",
                          },
                        }}
                      >
                        {Pages?.submenuItems?.map((item) => (
                          <MenuItem
                            key={item}
                            onClick={() => handleMenuItemClick(item)}
                          >
                            <Typography>{item}</Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  </div>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/home");
              }}
            >
              <img src={Logo} alt="Logo" className="logo-image" />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {Pages?.map((page) => (
                <Fragment key={page?.name}>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(event) => handleClick(event, page)}
                    style={{
                      textWrap: "nowrap",
                      padding: " 0 10px ",
                    }}
                    sx={
                      checkurl() ===
                        `${page?.name.toLowerCase().replace(" ", "-")}`
                        ? { ...buttonStyleactive }
                        : { ...buttonStyleaNonctive }
                    }
                  >
                    {page?.name}
                  </Button>
                  {page?.submenuItems?.length > 0 && (
                    <Menu
                      id={`menu-${page?.name}`}
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl && activeMenu === page?.name)}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": `button-${page?.name}`,
                      }}
                      sx={{
                        width: "281px",
                      }}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          backgroundColor: "transparent",
                          "& .MuiMenuItem-root": {
                            color: "#fff",
                            borderRadius: "16px",
                            fontSize: "18px",
                            fontWeight: "500",
                            lineHeight: "24px",
                            textAlign: "left",
                          },
                          "& .MuiList-root": {
                            paddingBottom: 0,
                            paddingTop: 0,
                            borderRadius: "16px",
                            padding: "24px 24px 30px 24px",
                            background: "#000",
                            width: "281px",
                          },
                          "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "#000",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      keepMounted
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      {page?.submenuItems.map((submenu, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => handleMenuItemClick(submenu)}
                          sx={{
                            display:
                              !isAuthenticated && submenu === "Watchlist"
                                ? "none !important"
                                : "block",
                          }}
                        >
                          <Typography>{submenu}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </Fragment>
              ))}
            </Box>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                width: "100%",
              }}
            >
              {userRole === "producer" &&
                location.pathname !== "/movieUpload" && (
                  <div>
                    <Button
                      startIcon={<Add />}
                      sx={{
                        backgroundColor: "#fed530",
                        color: "black",
                        borderRadius: "45px",
                        mx: 2,
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "flex",
                        },
                        textTransform: "initial",
                        "&:hover": {
                          backgroundColor: "#e4c200",
                        },
                      }}
                      onClick={() => {
                        navigate("/movieUpload");
                      }}
                      disabled={user?.approval !== "approve"}
                    >
                      Upload Movie
                    </Button>
                    {user?.approval !== "approve" && (
                      <span
                        style={{
                          fontSize: "small",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                        }}
                      >
                        This Account is not Approved
                      </span>
                    )}
                  </div>
                )}
              <SearchCard />
              <Box sx={{ flexGrow: 0 }}>
                {isAuthenticated ? (
                  <div
                    onClick={handleOpenUserMenu}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    aria-controls={open ? "fade-menu" : undefined}
                    aria-haspopup="true"
                  >
                    <IconButton sx={{ p: 0 }}>
                      {!loading ? (
                        <Avatar
                          style={{ marginRight: "12px" }}
                          alt="Avatar"
                          src={user?.profile_pic || AvatarImg}
                        />
                      ) : (
                        <Skeleton
                          variant="circular"
                          width={40}
                          height={40}
                          sx={{ background: " #333333", marginRight: "1rem" }}
                        />
                      )}
                    </IconButton>
                    <Typography
                      sx={{
                        color: "#FED530",
                        fontFamily: "DM Sans",
                        fontSize: "16px",
                        fontWeight: "700",
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "block",
                        },
                      }}
                    >
                      {!loading ? (
                        <> {user?.name}</>
                      ) : (
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", background: " #333333" }}
                          width={200}
                        />
                      )}
                    </Typography>
                  </div>
                ) : (
                  <div
                    onClick={handleNavigateTologin}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <IconButton sx={{ p: 0 }}>
                      <Avatar
                        style={{ marginRight: "12px" }}
                        alt="Avatar"
                        src={AvatarImg}
                      />
                    </IconButton>
                    <Typography
                      sx={{
                        color: "#FED530",
                        fontFamily: "DM Sans",
                        fontSize: "16px",
                        fontWeight: "700",
                        textWrap: "nowrap",
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "block",
                        },
                      }}
                    >
                      {storedUserName}
                    </Typography>
                  </div>
                )}
                <Menu
                  sx={{
                    width: "281px",
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      backgroundColor: "transparent",
                      "& .MuiMenuItem-root": {
                        color: "#fff",
                        borderRadius: "16px",
                        fontSize: "18px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        textAlign: "left",
                      },
                      "& .MuiList-root": {
                        paddingBottom: 0,
                        paddingTop: 0,
                        borderRadius: "16px",
                        padding: "24px 24px 30px 24px",
                        background: "#000",
                        width: "281px",
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "#000",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  keepMounted
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  open={Boolean(anchorElUser)}
                  onClose={() => setAnchorElUser(null)}
                >
                  {isAuthenticated &&
                    getProducercurrentrole()?.map((setting) => (
                      <MenuItem
                        key={setting}
                        onClick={() => handleCloseUserMenu(setting)}
                      >
                        <Typography textAlign="center">
                          {setting}
                          {/* {setting === "Logout" ? (
                            <img
                              src={logouticon}
                              alt="logouticon"
                              height="20px"
                            />
                          ) : null} */}
                        </Typography>
                      </MenuItem>
                    ))}
                </Menu>
              </Box>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
