import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import "../../scss/Analytics.scss"; // Import the CSS file for styling
import filterIcon from "../../images/AnalyticsPage/ButtonIcon.svg";
import { useParams } from "react-router-dom";
import { getanalytics } from "../../services/analytics";
import { Button } from "react-bootstrap";
import { Menu, MenuItem } from "@mui/material";

drilldown(Highcharts);

const GraphAnalytics = () => {
  const { id } = useParams();
  const [days, setdays] = useState(7);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [totalwatchtime, settotalwatchtime] = useState("");

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "column",
      backgroundColor: "#161616",
      height: 400,
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "category",
      labels: {
        style: {
          color: "#FFFFFF",
        },
      },
      lineColor: "#FFFFFF",
      tickColor: "#FFFFFF",
    },
    yAxis: {
      title: {
        text: "Hours",
        style: {
          color: "#FFFFFF",
        },
      },
      labels: {
        style: {
          color: "#FFFFFF",
        },
      },
      gridLineColor: "#333333",
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          color: "#FFFFFF",
        },
        pointWidth: 32,
      },
    },
    legend: {
      itemStyle: {
        color: "#FFFFFF",
      },
    },
    series: [],
    credits: {
      enabled: false, // Hide the Highcharts.com credit link
    },
  });

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchData = async () => {
    try {
      const response = await getanalytics(
        `${parseInt(id)}&days=${days}&search=`
      );
      if (response?.data?.data) {
        const analyticsData = response.data?.data?.analytics;
        const hours = Math.floor(response.data.data?.total_watch_time / 3600);
        settotalwatchtime(hours);
        const seriesData = analyticsData?.map((item) => [
          item.date,
          Math.floor(item?.total_watch_time / 3600),
        ]);

        // Update chart options with the new data
        setChartOptions((prevOptions) => ({
          ...prevOptions,
          series: [
            {
              name: "Watch hours",
              color: "#FFD700",
              data: seriesData,
            },
          ],
        }));
      }
    } catch (error) {
      console.log(error, "Data fetch error");
    }
  };
  useEffect(() => {
    fetchData();
  }, [days]);

  const handleMenuItemClick = (value) => {
    setdays(value);
  };
  return (
    <div className="analytics-container">
      <div className="header">
        <div className="title">
          <h2>Analytics</h2>
          <p>View user watch analytics</p>
        </div>
        <div>
          <div>
            <Button
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleOpen}
              variant="text"
              className="filter-button"
            >
              Last {days} days{" "}
              <img src={filterIcon} alt="filter icon" height="12px" />
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              sx={{
                width: "281px",
              }}
              className="lang"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,

                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  backgroundColor: "#0000", // Inline style for the Paper component

                  "& .MuiMenuItem-root": {
                    color: "#fff",
                    borderRadius: "16px",
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    textAlign: "left",
                  },
                  "& .MuiList-root": {
                    paddingBottom: 0,
                    paddingTop: 0,
                    borderRadius: "16px",
                    padding: "24px 24px 30px 24px",
                    background: "#000",
                    width: "281px",
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "#000",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() => {
                  handleMenuItemClick(7);
                }}
              >
                Last 7 days
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleMenuItemClick(14);
                }}
              >
                Last 14 days
              </MenuItem>{" "}
              <MenuItem
                onClick={() => {
                  handleMenuItemClick(35);
                }}
              >
                Last 35 days
              </MenuItem>{" "}
            </Menu>
          </div>
        </div>
      </div>
      <div className="chart-container">
        <div className="total-watch-hours-row">
          <div className="total-watch-hours">
            <p>Total Watch Hours</p>
            <h1>{totalwatchtime}hrs</h1>
          </div>
          
        </div>
        <div className="last-days">
          <h6>In Last {days} days</h6>
        </div>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    </div>
  );
};

export default GraphAnalytics;
