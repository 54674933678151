import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import movie_img from "../../images/AccountPage/movie.png";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import "./buyModel.css";

const BuyRentModal = ({
  show,
  handleClose,
  value,
  handlePayment,
  isDoingPayement,
}) => {
  const isBookingAllowed =
    value?.published === true ||
    (value?.advance_booking_date &&
      moment(value?.advance_booking_date).isSameOrBefore(moment(), "day"));

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      className="buy_modal"
    >
      <Modal.Body>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={10}
            sx={{ textAlign: "left", mb: "15px", px: 2 }}
          >
            <Typography variant="para" className="screenParas">
              Almost Done
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: "#fff", // Set icon color to white
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon sx={{ color: "#fff" }} /> {/* White color for icon */}
          </IconButton>
        </Grid>
        <Grid container spacing={2} sx={{ pb: 3 }}>
          <Grid item xs={12} sm={3}>
            <img
              src={value?.thumbnail}
              alt="movie_img"
              style={{ width: "100%", height: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography variant="h6" className="screenParas2">
              {value?.title}
            </Typography>
            <Typography variant="para" className="screenParas3">
              {value?.Language?.name}
            </Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              {value?.rating}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M15.5754 5.72266L10.6568 4.97133L8.4521 0.275329C8.28743 -0.0753376 7.7121 -0.0753376 7.54743 0.275329L5.34343 4.97133L0.424766 5.72266C0.0207661 5.78466 -0.140567 6.276 0.142766 6.566L3.7161 10.2287L2.87143 15.4067C2.8041 15.818 3.24343 16.1267 3.60677 15.9247L8.0001 13.4967L12.3934 15.9253C12.7534 16.1253 13.1968 15.8227 13.1288 15.4073L12.2841 10.2293L15.8574 6.56666C16.1408 6.276 15.9788 5.78466 15.5754 5.72266Z"
                    fill="#FFC107"
                    stroke="#FFC107"
                  />
                </svg>
              </div>{" "}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={0} className="prize">
          <Grid item xs={12} sm={9} sx={{ pb: 1 }}>
            <Typography variant="para" className="screenParas4">
              Total Payable Amount
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} sx={{ textAlign: "right" }}>
            <Typography variant="para" className="screenParas4">
              ₹ {value?.price_with_gst}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pt: "12px" }}>
          <Grid item xs={12} sm={9}>
            <Typography variant="para" className="screenParas4">
              Ticket Price
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} sx={{ textAlign: "right" }}>
            <Typography variant="para" className="screenParas4">
              ₹ {value?.price}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pt: "12px" }}>
          <Grid item xs={12} sm={9}>
            <Typography variant="para" className="screenParas4">
              Integrated GST (IGST) @ {value?.gst_rate}%
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} sx={{ textAlign: "right" }}>
            <Typography variant="para" className="screenParas4">
              ₹ {value?.gst_amount}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pt: "18px" }}>
          <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
            {isBookingAllowed ? (
              <Button
                variant="secondary"
                className="buybutton"
                onClick={handlePayment}
                disabled={isDoingPayement}
              >
                Pay
                <span className="amount">₹ {value?.price_with_gst}</span>
              </Button>
            ) : (
              <Button variant="secondary" className="buybutton" disabled>
                Booking for this movie starts from{" "}
                {moment(value?.advance_booking_date).format("DD MMM YYYY")}
              </Button>
            )}
          </Grid>
        </Grid>
      </Modal.Body>
    </Modal>
  );
};

export default BuyRentModal;
