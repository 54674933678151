import React, { useEffect, useState } from "react";
import MoviesConstruction from "../UnderConstruction/MoviesConstruction";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Tooltip, Grid } from "@mui/material";
import "../../scss/WatchListMovies.scss";
import Banner1 from "../../images/MovieBanner/Uc.jpg";
import lack from "../../images/moredetails/Lock.svg";
import save from "../../images/moredetails/Save.svg";
import WishedList from "../../images/moredetails/WishedList.svg";
import info from "../../images/moredetails/Info.svg";
import share from "../../images/moredetails/Share.svg";
import clock from "../../images/moredetails/clock.svg";
import play from "../../images/moredetails/play.svg";
import { showPlayButton } from "../HomePage/showthePlayButton";
import { FaStar } from "react-icons/fa";
import SharePopUpBox from "../../common/sharePopUp/SharePopUp";
import { toggleWishlist } from "../../services/home";
import toast from "react-hot-toast";

export default function CategorisedMovieList({
  movies = [],
  navigateTo,
  seeMore,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  const [showFullDescription, setShowFullDescription] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const WatchListFun = async (id) => {
    const payload = {
      movie_id: id,
    };
    if (isAuthenticated === "true") {
      try {
        const response = await toggleWishlist(payload);
        if (response?.data?.status) {
          toast.success(response?.data?.message);
          seeMore();
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Please login to add to watchlist");
    }
  };

  const handleSeeMore = (id) => {
    const encodedId = btoa(id);
    navigate(`/moredetails/${encodedId}`, { state: { encodedId } });
  };

  const toggleDescription = (index) => {
    setShowFullDescription((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const convertSecondsToHrMin = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return hours > 0 ? `${hours} Hr ${minutes} Min` : `${minutes} Min`;
  };

  const [shareDetials, setShareDetials] = useState(false);
  const [shareMovie, setShareMovie] = useState("");

  const OpenSharePopUp = (item) => {
    setShareMovie(item?.id);
    setShareDetials(true);
  };

  const CloseSharePopUp = () => {
    setShareDetials(false);
  };

  return (
    <>
      {!movies.length ? (
        <MoviesConstruction navigateTo={navigateTo} showLanguageText={false} />
      ) : (
        <>
          <Grid container sx={{ p: 4 }}>
            {movies?.map((item, idx) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={2.4}
                  sx={{ mb: 2, px: 2 }}
                  key={idx}
                >
                  <div
                    className="image-container-watchlist-page"
                    onClick={() =>
                      isAuthenticated === "true"
                        ? handleSeeMore(item?.id)
                        : navigate("/login")
                    }
                  >
                    <img
                      src={item?.thumbnail}
                      alt="Watch History"
                      style={{
                        height: "400px",
                        width: "100%",
                        borderRadius: "0.25rem",
                      }}
                      // className="image-watchlist-page"
                    />
                    <div className="overlay-watchlist-page">
                      <div className="content">
                        <div className="info">
                          {item?.Certificate?.name && (
                            <div
                              className="UA-c"
                              style={{ marginBottom: "0.5rem" }}
                            >
                              {item?.Certificate?.name}
                            </div>
                          )}
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={clock}
                              alt="Clock Icon"
                              style={{
                                height: "20px",
                                width: "20px",
                                marginRight: "5px",
                              }}
                            />
                            <span className="duration">
                              {item?.duration
                                ? convertSecondsToHrMin(item?.duration)
                                : ""}
                            </span>
                            <span className="stars">
                              <FaStar
                                color="gold"
                                style={{
                                  marginBottom: "5px",
                                  marginLeft: "5px",
                                }}
                              />{" "}
                              {item?.rating}
                            </span>
                          </div>
                        </div>
                        <Typography variant="h6">{item?.title}</Typography>

                        <Typography
                          className="description"
                          style={{ marginBottom: "10px" }}
                        >
                          <div
                            className={`text ${
                              showFullDescription[idx] ? "full" : "truncated"
                            }`}
                          >
                            {item?.description}
                          </div>
                          {item?.description.split("\n")?.length > 3 &&
                            !showFullDescription[idx] && (
                              <span
                                className="see-more"
                                onClick={() => toggleDescription(idx)}
                              >
                                ....
                              </span>
                            )}
                        </Typography>
                        <div className="icons">
                          {!showPlayButton(item?.Movie) ? (
                            <Tooltip title="Pay/Rent Now" placement="top">
                              <img
                                src={lack}
                                alt="Lock Icon"
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  isAuthenticated === "true"
                                    ? handleSeeMore(item?.id)
                                    : navigate("/login")
                                }
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Watch Now" placement="top">
                              <img
                                src={play}
                                alt="Lock Icon"
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  isAuthenticated === "true"
                                    ? handleSeeMore(item?.id)
                                    : navigate("/login")
                                }
                              />
                            </Tooltip>
                          )}
                          {/* <Tooltip
                            title={`${"My Watch List"} `}
                            placement="top"
                          >
                            <img
                              src={WishedList}
                              alt="Save Icon"
                              style={{
                                height: "40px",
                                width: "40px",
                                cursor: "pointer",
                              }}
                              onClick={() => WatchListFun(item?.id)}
                            />
                          </Tooltip> */}
                          <Tooltip
                            title={`${
                              item?.is_saved
                                ? "My Watch List"
                                : "Add Watch List"
                            } `}
                            placement="top"
                          >
                            <img
                              src={item?.is_saved ? WishedList : save}
                              alt="Save Icon"
                              style={{ height: "40px", width: "40px" }}
                              onClick={(event) => {
                                event.preventDefault(); // Prevent default behavior
                                event.stopPropagation();
                                WatchListFun(item?.id);
                              }}
                              // onClick={() => WatchListFun(item?.id)}
                            />
                          </Tooltip>
                          <Tooltip title="Share" placement="top">
                            <img
                              src={share}
                              alt="Share Icon"
                              style={{
                                height: "40px",
                                width: "40px",
                                cursor: "pointer",
                              }}
                              onClick={(event) => {
                                event.preventDefault(); // Prevent default behavior
                                event.stopPropagation();
                                OpenSharePopUp(item);
                              }}
                              // onClick={() => OpenSharePopUp(item)}
                            />
                          </Tooltip>

                          <div className="icon-right">
                            <Tooltip title="More Info" placement="top">
                              <img
                                src={info}
                                alt="Info Icon"
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleSeeMore(item?.id)}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
            <SharePopUpBox
              open={shareDetials}
              onClose={CloseSharePopUp}
              data={shareMovie}
            ></SharePopUpBox>
          </Grid>
          <SharePopUpBox
            open={shareDetials}
            onClose={CloseSharePopUp}
            data={shareMovie}
          ></SharePopUpBox>
        </>
      )}
    </>
  );
}
